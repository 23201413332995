<template>
  <div class="form-horizontal" role="form">
    <b-form-group
      id="example text"
      label-cols-sm="2"
      label-cols-lg="2"
      label="Teacher name"
      label-for="teacher-name"
    >
      <b-form-input for="class-name" v-model="teacher.teacher"></b-form-input>
    </b-form-group>
    <b-form-group
      id="example text"
      label-cols-sm="2"
      label-cols-lg="2"
      label="Class Name"
      label-for="class"
    >
      <b-form-input for="class" v-model="teacher.classN"></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
import { schoolMethods } from "@/state/helpers.js";

export default {
  name: "newClass",
  data() {
    return {
      teacher: {
        teacher: "",
        classN: "",
      },
    };
  },
  mounted() {
    this.saveTeacher({ ...this.teacher });
  },
  watch: {
    teacher: {
      deep: true,
      handler(newVal) {
        this.saveTeacher({ ...newVal });
      },
    },
  },
  methods: {
    ...schoolMethods,
  },
};
</script>
