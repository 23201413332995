<template>
  <div class="form-horizontal" role="form">
    <div class="table-responsive mt-3">
      <h2>Students in the class</h2>
      <table
        class="table table-centered datatable dt-responsive nowrap"
        style="border-collapse: collapse; border-spacing: 0; width: 100%"
      >
        <thead class="thead-light">
          <tr>
            <th style="width: 20px">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="customercheck"
                />
                <label class="custom-control-label" for="customercheck"
                  >&nbsp;</label
                >
              </div>
            </th>
            <th>Student</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Wallet Balance</th>
            <th>Joining Date</th>
            <th style="width: 120px">Remove</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in studentsInTheClass" :key="index">
            <td>
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  :id="`customercheck${index}`"
                />
                <label
                  class="custom-control-label"
                  :for="`customercheck${index}`"
                  >&nbsp;</label
                >
              </div>
            </td>
            <td>{{ item.name }}</td>
            <td>{{ item.email }}</td>
            <td>{{ item.phone }}</td>
            <td>{{ item.balance }}</td>
            <td>{{ item.date }}</td>
            <td>
              <a
                @click="removeStudent(item, index)"
                class="text-danger"
                v-b-tooltip.hover
                title="delete"
              >
                <i class="mdi mdi-trash-can font-size-18"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-responsive mt-5">
      <h2>Students</h2>
      <table
        class="table table-centered datatable dt-responsive nowrap"
        style="border-collapse: collapse; border-spacing: 0; width: 100%"
      >
        <thead class="thead-light">
          <tr>
            <th style="width: 20px">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="customercheck"
                />
                <label class="custom-control-label" for="customercheck"
                  >&nbsp;</label
                >
              </div>
            </th>
            <th>Student</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Wallet Balance</th>
            <th>Joining Date</th>
            <th style="width: 120px">Add</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in students" :key="index">
            <td>
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  :id="`customercheck${index}`"
                />
                <label
                  class="custom-control-label"
                  :for="`customercheck${index}`"
                  >&nbsp;</label
                >
              </div>
            </td>
            <td>{{ item.name }}</td>
            <td>{{ item.email }}</td>
            <td>{{ item.phone }}</td>
            <td>{{ item.balance }}</td>
            <td>{{ item.date }}</td>
            <td>
              <a
                @click="addStudent(item, index)"
                class="text-info"
                v-b-tooltip.hover
                title="add"
              >
                <i class="mdi mdi-plus font-size-18"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { schoolMethods } from "@/state/helpers.js";

export default {
  name: "newClassRoom",
  props: {
    items: {
      type: Array,
      default: () => [
        {
          name: "Carolyn Harvey",
          email: "CarolynHarvey@rhyta.com",
          phone: "580-464-4694",
          balance: "$ 3245",
          date: "06 Apr, 2020",
        },
        {
          name: "Angelyn Hardin",
          email: "AngelynHardin@dayrep.com",
          phone: "913-248-2690",
          balance: "$ 2435",
          date: "05 Apr, 2020",
        },
        {
          name: "Carrie Thompson",
          email: "CarrieThompson@rhyta.com",
          phone: "734-819-9286",
          balance: "$ 2653",
          date: "04 Apr, 2020",
        },
        {
          name: "Kathleen Haller",
          email: "KathleenHaller@dayrep.com",
          phone: "313-742-3333",
          balance: "$ 2135",
          date: "03 Apr, 2020",
        },
        {
          name: "Martha Beasley",
          email: "MarthaBeasley@teleworm.us",
          phone: "301-330-5745",
          balance: "$ 2698",
          date: "02 Apr, 2020",
        },
        {
          name: "Kathryn Hudson",
          email: "KathrynHudson@armyspy.com",
          phone: "414-453-5725",
          balance: "$ 2758",
          date: "02 Apr, 2020",
        },
        {
          name: "Robert Bott",
          email: "RobertBott@armyspy.com",
          phone: "712-237-9899",
          balance: "$ 2836",
          date: "01 Apr, 2020",
        },
        {
          name: "Mary McDonald",
          email: "MaryMcDonald@armyspy.com",
          phone: "317-510-25554",
          balance: "$ 3245",
          date: "31 Mar, 2020",
        },
        {
          name: "Keith Rainey",
          email: "KeithRainey@jourrapide.com",
          phone: "214-712-1810",
          balance: "$ 3125",
          date: "30 Mar, 2020",
        },
        {
          name: "Anthony Russo",
          email: "AnthonyRusso@jourrapide.com",
          phone: "412-371-8864",
          balance: "$ 2456",
          date: "30 Mar, 2020",
        },
        {
          name: "Donna Betz",
          email: "DonnaBetz@jourrapide.com",
          phone: "626-583-5779",
          balance: "$ 3423",
          date: "29 Mar, 2020",
        },
        {
          name: "Angie Andres",
          email: "AngieAndres@armyspy.com",
          phone: "213-494-4527",
          balance: "$ 3245",
          date: "28 Apr, 2020",
        },
      ],
    },
  },
  data() {
    return {
      students: [],
      studentsInTheClass: [],
    };
  },
  mounted() {
    this.students = [...this.items];
    this.saveStudents([]);
  },

  methods: {
    ...schoolMethods,

    addStudent(student, index) {
      this.studentsInTheClass.push(student);
      this.students.splice(index, 1);
      this.saveStudents(this.studentsInTheClass);
    },

    removeStudent(student, index) {
      this.students.push(student);
      this.studentsInTheClass.splice(index, 1);
      this.saveStudents(this.studentsInTheClass);
    },
  },
};
</script>
