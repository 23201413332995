<template>
  <div id="accordion" class="custom-accordion-arrow">
    <accordion
      v-for="(arcod, index) in list"
      :key="index"
      v-show="arcod.exist"
      :title="arcod.title"
      arc="number-"
      :edit="arcod.button"
      :index="index"
      :step="list"
      :next="index + 1"
      @finishButton="$emit('finish')"
    >
      <component :is="arcod.component" />
    </accordion>
  </div>
</template>

<script>
import accordion from "./accordion";
export default {
  name: "accordionList",
  components: {
    accordion,
  },
  props: {
    list: { type: Array, default: () => [] },
  },
};
</script>
