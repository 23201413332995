<template>
  <div class="card w-100">
    <a
      v-b-toggle="arc + index"
      id="linkCard"
      class="text-dark w-100"
      data-toggle="collapse"
      aria-expanded="true"
    >
      <div
        class="row justify-content-between align-items-center card-header"
        id="gen-step-headingOne"
      >
        <h5 class="font-size-14 m-0">
          <i class="mdi mdi-chevron-up accor-arrow-icon"></i>
          {{ title }}
        </h5>
        <button class="btn btn-primary" v-show="edit">Editar</button>
      </div>
    </a>
    <b-collapse
      :id="arc + index"
      visible
      accordion="my-accordion"
      data-parent="#accordion"
    >
      <div class="card-body">
        <div class="col w-100 p-3">
          <slot />
          <button
            class="btn btn-primary w-50"
            v-b-toggle="arc + next"
            @click="
              next < step.length ? closeStep(index) : $emit('finishButton')
            "
          >
            {{ buttonName }}
          </button>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: "accordion",

  props: {
    title: { type: String, default: "" },
    arc: { type: String, default: "" },
    edit: { type: Boolean, default: false },
    index: { type: Number, default: null },
    next: { type: Number, default: null },
    step: { type: Array, default: () => [] },
  },
  computed: {
    buttonName() {
      return this.next < this.step.length ? "Registrar" : "Finalizar";
    },
  },
  methods: {
    closeStep(index) {
      this.step[index].button = true;
      this.step[index + 1].exist = true;
    },
  },
};
</script>
