<template>
  <div class="form-horizontal" role="form">
    <b-form-group
      id="example text"
      label-cols-sm="2"
      label-cols-lg="2"
      label="Class Name"
      label-for="class-name"
    >
      <b-form-input
        for="class-name"
        value="Ax214"
        v-model="classRoom.name"
      ></b-form-input>
    </b-form-group>
    <b-form-group
      id="example text"
      label-cols-sm="2"
      label-cols-lg="2"
      label="Grade"
      label-for="grade"
    >
      <b-form-input
        for="grade"
        value="7ª Série"
        v-model="classRoom.grade"
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
import { schoolMethods } from "@/state/helpers.js";
export default {
  name: "newClass",
  data() {
    return {
      classRoom: {
        name: "",
        grade: "",
      },
    };
  },
  mounted() {
    this.saveClassRoom({ ...this.classRoom });
  },
  watch: {
    classRoom: {
      deep: true,
      handler(newVal) {
        this.saveClassRoom({ ...newVal });
      },
    },
  },
  methods: {
    ...schoolMethods,
  },
};
</script>
