<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <accordionList :list="step" @finish="concluir" />
  </Layout>
</template>

<script>
import { schoolMethods, schoolGetters } from "@/state/helpers.js";
import Layout from "../layouts/main";
import PageHeader from "@/components/page-header";
import newClassRoom from "./formularios/newClassRoom";
import newClass from "./formularios/newClass";
import teacherClass from "./formularios/teacherClass";
import accordionList from "../../components/accordion/accordionList";

export default {
  page: {
    title: "step",
    meta: [{ name: "description" }],
  },
  components: { Layout, PageHeader, accordionList },
  data() {
    return {
      title: "step",
      items: [
        {
          text: "Utility",
          href: "/",
        },
        {
          text: "Passo a Passo",
          active: true,
        },
      ],
      step: [
        {
          title: "Passo 1: Nova Sala",
          component: newClass,
          exist: true,
          button: false,
        },
        {
          title: "Passo 2: Novos Alunos",
          component: newClassRoom,
          exist: false,
          button: false,
        },
        {
          title: "Passo 3: Professores",
          component: teacherClass,
          exist: false,
          button: false,
        },
      ],
      cadastro: {
        nameClass: null,
        serie: null,
        students: [],
        teacherName: null,
        classN: null,
      },
    };
  },

  computed: {
    ...schoolGetters,
  },

  methods: {
    ...schoolMethods,

    concluir() {
      let nclass = { ...this.getNewClass };
      console.log(nclass);
    },
  },
};
</script>
